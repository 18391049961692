<template>
	<b-card no-body >
		<b-tabs pills nav-class="bodyCardNav"   nav-wrapper-class="col-12 col-md-4" content-class="bodyCard col-12 col-md-8" vertical>
			<b-tab :title="$t('Category files')" disabled></b-tab>

			<b-tab v-for="(type) in typeDocument" :key="type.id" :title="languageUser == 'Spanish' ? type.type :type.nameEng" active @click="getFileResources(type)">

				<template #title>
					<b-icon icon="folder"></b-icon> {{ languageUser == 'Spanish' ? type.type : type.nameEng }}
				</template>

				<div class="text-center mt-2 mb-2" v-if="isLoadingFiles">
					<b-spinner label="Loading..." variant="info" />Loading...
				</div>

				<div v-if="!isLoadingFiles">
					<b-col md="12" v-if="myFiles.myFilesOne.length">
						<app-timeline>
							<app-timeline-item variant="warning" v-for="(file) in myFiles.myFilesOne" :key="file.id">

								<div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
								<p>
									<b-img :src="setIconFile(file.tipeFile)" height="auto" width="30" class="mr-1" />
									<b class="align-bottom">{{file.lastRoute}}</b>
								</p>
								<div>
									<!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="primary" > {{$t('Download file')}} </b-button> -->
									<a class="btn btn-sm btn-primary" @click="openFile(file)">{{$t('Download file')}}</a>
								</div>
								</div>

							</app-timeline-item>

							<app-timeline-item variant="warning" v-for="( file) in myFiles.myFilesTwo" :key="file.id">

								<div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
									<p>
										<b-img
											:src="setIconFile(file.tipeFile)"
											height="auto"
											width="30"
											class="mr-1"
										/>
										<b class="align-bottom">{{file.lastRoute}}</b>
									</p>
									<div>
										<a class="btn btn-sm btn-primary" @click="openFile(file)">{{$t('Download file')}}</a>
									</div>
								</div>
							</app-timeline-item>
						</app-timeline>
					</b-col>

					<b-col md="12" v-else>
						<b-alert variant="danger" show class="mb-0">
							<div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" /> {{$t('You have no files in the selected contract')}} </div>
						</b-alert>
					</b-col>
				</div>
			</b-tab>
		</b-tabs>
  	</b-card>
</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { VBToggle,  VBTooltip } from 'bootstrap-vue'
import { utils } from "@/modules/owners/mixins/utils"

export default {
	mixins:[utils],
	components:{
		AppTimeline,
		AppTimelineItem,
	},
  	directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
	data(){
		return {
			isLoadingFiles:false,
			imgUrl: process.env.VUE_APP_IMG_SRC_API,
		}
	},
	computed:{
		...mapState('owners',['myFiles','typeDocument']),
		...mapState('auth', ['myContracts','user']),
		languageUser(){
			const initial = 'English'
			return this.user.languageName == 'English' ? initial : 'Spanish'
		},
	},
	methods:{
		...mapActions('owners', ['fetchFilesByContracts','pushActionsService']),
		...mapMutations('owners', ['setResources','setMyFiles']),
		async getFileResources(type){

			const { id } = type
			this.isLoadingFiles = true
			const { id: idContract } = this.myContracts.contractSelected // id del contrado seleccionado
			const { firstHalfFiles:filesOne, secondHalfFiles:filesTwo  }  = await this.fetchFilesByContracts({idContract,idType:id})
			this.setMyFiles({filesOne, filesTwo} )
			this.isLoadingFiles = false

			const aditionalInfo = this.aditionalInfo(type)
			await this.pushData('onClick', 'selectCategoryFile', 'selectCategoryFile', this.$route.name, aditionalInfo)

		},
		aditionalInfo(type = null, file = null){

			let aditionalInfo = ''

			if(type){
				aditionalInfo += this.languageUser == 'Spanish' ? ` ${type.type}` : ` ${type.nameEng}` //concateno
			}

			if(file){
				aditionalInfo +=  ` ${file.name}`  //concateno
			}

			return aditionalInfo
		},
		async openFile(file){
			const type = null
			const { fileRoute } = file
			const fullPath = `${this.imgUrl.slice(0, -1)}${fileRoute}`
			window.open(fullPath, '_blank');

			const aditionalInfo = this.aditionalInfo(type, file)
			await this.pushData('onClick', 'downloadFile', 'downloadFile', this.$route.name, aditionalInfo)

		},
		setIconFile(fileType){
			let routeIcon = require('@/assets/images/icons/icon-file.png')

			if(fileType === 'imagen'){
				routeIcon = require('@/assets/images/icons/icon-image.png')
			}

			if(fileType === 'pdf'){
				routeIcon = require('@/assets/images/icons/icon-pdf.png')
			}
			if(fileType === 'video'){
				routeIcon = require('@/assets/images/icons/icon-video.png')
			}
			if(fileType === 'audio'){
				routeIcon = require('@/assets/images/icons/icon-audio.png')
			}
			if(fileType === 'word'){
				routeIcon = require('@/assets/images/icons/icon-word.jpg')
			}

			if(fileType === 'excel'){
				routeIcon = require('@/assets/images/icons/icon-excel.png')
			}

			if(fileType === 'ppt'){
				routeIcon = require('@/assets/images/icons/icon-ppt.png')
			}
			return routeIcon
		},

	},
}
</script>
<style >
.bodyCardNav{
  background: #f0f0f0;
  border: #000;
  padding: 16px 0px 16px 0px;

}
.bodyCardNav .nav-link{
    justify-content: left;

}
 .bodyCard {
    border: solid 1px;
    padding: 30px 5px 30px 5px;
    max-height: 417px;
    overflow: auto;

}

.bodyCardNav .nav-link.disabled {
    color: #000;
    font-weight: 700;
    font-size: 18px;
}
</style>