<template>
  <div class="p-1">
    <div class="text-center mt-2 mb-2" v-if="isLoadingFiles">
      <b-spinner label="Loading..." variant="info" />Loading...
    </div>

    <app-timeline v-if="myFiles.resources.length && !isLoadingFiles" class="">
      <app-timeline-item variant="warning" v-for="(file, index) in myFiles.resources" :key="file.id" class="pr-1">
        <template class="mb-1 mb-sm-0">
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
            <h6>{{file.name.toUpperCase() }}</h6>
            <div>
              <label class="mb-1 mt-0 mr-1">{{ formatThisDate(file.createdate) }}</label>
              <b-badge variant="light-primary"> {{ typeDocumentName(file) }} </b-badge>
            </div>
          </div>
        </template>
        <div class="short-description">
          <p>{{file.description}}</p>
        </div>

        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
          <p>
            <b-img :src="setIconFile(file.tipeFile)" height="auto" width="30" class="mr-1" />
            <b class="align-bottom">{{file.lastRoute}}</b>
          </p>
          <div>
            <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="primary" > {{$t('Download file')}} </b-button> -->
            <a class="btn btn-sm btn-primary" @click="openFile(file)">{{$t('Download file')}}</a>
          </div>
        </div>
      </app-timeline-item>
    </app-timeline>

    <b-row v-else>
      <b-col md="12">
        <b-alert variant="danger" show class="mb-0">
          <div class="alert-body"><feather-icon icon="InfoIcon" class="mr-50" /> {{$t('No resources found')}} </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { VBToggle,  VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@/helpers/helpers'
import { utils } from "@/modules/owners/mixins/utils"

export default {
	mixins:[utils],

  components:{
    AppTimeline,
    AppTimelineItem,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data(){
    return {
			isLoadingFiles:false,
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
    }
  },
  computed:{
		...mapState('owners',['myFiles','typeDocument']),
		...mapState('auth',['user']),
		languageUser(){
			const initial = 'English'
			return this.user.languageName == 'English' ? initial : 'Spanish'
		},
  },
  methods:{
		...mapActions('owners', ['fetchResources']),
		...mapMutations('owners', ['setResources']),

    setIconFile(fileType){
      let routeIcon = require('@/assets/images/icons/icon-file.png')

      if(fileType === 'imagen'){
        routeIcon = require('@/assets/images/icons/icon-image.png')
      }

      if(fileType === 'pdf'){
        routeIcon = require('@/assets/images/icons/icon-pdf.png')
      }
      if(fileType === 'video'){
        routeIcon = require('@/assets/images/icons/icon-video.png')
      }
      if(fileType === 'audio'){
        routeIcon = require('@/assets/images/icons/icon-audio.png')
      }
      if(fileType === 'word'){
        routeIcon = require('@/assets/images/icons/icon-word.jpg')
      }

      if(fileType === 'excel'){
        routeIcon = require('@/assets/images/icons/icon-excel.png')
      }

      if(fileType === 'ppt'){
        routeIcon = require('@/assets/images/icons/icon-ppt.png')
      }
      return routeIcon
    },
    documentsById(documents, type){
      return documents?.filter(docs=>docs.type == type) || []
    },
    typeDocumentName(item){
      const type = this.typeDocument.find(type => type.id == item.type)
      return this.languageUser == 'Spanish' ? type.type : type.nameEng
    },
		aditionalInfo(type = null, file = null){
			let aditionalInfo = ''

			if(type){
				aditionalInfo += this.languageUser == 'Spanish' ? ` ${type.type}` : ` ${type.nameEng}` //concateno
			}

			if(file){
				aditionalInfo +=  ` ${file.name}`  //concateno
			}

			return aditionalInfo
		},
		async openFile(file){
			const type = null
			const { fileRoute } = file
			const fullPath = `${this.imgUrl.slice(0, -1)}${fileRoute}`
			window.open(fullPath, '_blank');

			const aditionalInfo = this.aditionalInfo(type, file)
			await this.pushData('onClick', 'downloadFile', 'downloadFile', this.$route.name, aditionalInfo)

		},
    formatThisDate(date){
      return formatDate({date, language: this.user?.languageName})
    }
  },
}
</script>
<style scoped>
.short-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>