<template>
  <section style="padding: 1rem;">
    <SkeletonResources v-if="isLoadingFiles"/>
    <b-card v-else>
      <b-card-body>
        <b-tabs active>
			<b-tab @click="clickTabFiles($t('My files'))">
				<template #title>
					<b-img :src="require('@/assets/images/icons/folder.png')" height="auto" width="22" class="mr-1"/>
				<span>{{$t('My files')}}</span>
				</template>
				<ListMyFiles/>
          	</b-tab>
			<b-tab @click="clickTabFiles($t('My Documents'))">
				<template #title>
					<b-img :src="require('@/assets/images/icons/folder.png')" height="auto" width="20" class="mr-1"/>
				<span>{{$t('My Documents')}}</span>
				</template>
				<ListResources/>
			</b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {  mapActions, mapMutations, mapState } from 'vuex'
import SkeletonResources from '@/modules/owners/components/resources/skeletons/SkeletonResources'
import ListResources from '@/modules/owners/components/resources/ListResources'
import ListMyFiles from '@/modules/owners/components/resources/ListMyFiles'

import { utils } from '@/modules/owners/mixins/utils'

export default {
	mixins:[utils],
	components:{
		SkeletonResources,
		ListResources,
		ListMyFiles
	},
	async created(){
		await this.getDataResources()
	},
	data(){
		return {
			isLoadingFiles: false,
		}
	},
	computed:{
		...mapState('auth', ['myContracts']),
	},
	methods:{
		...mapActions('owners', ['fetchGetDocumentstype', 'fetchResources','pushActionsService']),
		...mapMutations('owners', ['setMyFiles','setTypeDocument', 'setResources']),
		async getDataResources(){
			await this.getFileType()
		},
		async getFileType(){
			this.isLoadingFiles = true
			const typeDocument= await this.fetchGetDocumentstype()
			this.setTypeDocument(typeDocument)

			const files = await this.fetchResources({
				membership: this.myContracts?.contractSelected?.membershipid,
				hotel: this.myContracts?.contractSelected?.resortsid
			})
			this.setResources(files)

			this.isLoadingFiles = false
		},
		async clickTabFiles(infoTab){
			await this.pushData('onClick', 'clickTabOption', 'clickTabOption', this.$route.name, infoTab) // push a back del registr
		},
	},
	watch: {
		myContracts: {
			deep: true,
			async handler() {
				await this.getDataResources()
			},
		},
	},

}
</script>

